/* OWASP Special Characters: https://www.owasp.org/index.php/Password_special_characters */
var specialCharacters = [
	' ',
	'!',
	'"',
	'#',
	'\\$',
	'%',
	'&',
	"'",
	'\\(',
	'\\)',
	'\\*',
	'\\+',
	',',
	'-',
	'\\.',
	'/',
	':',
	';',
	'<',
	'=',
	'>',
	'\\?',
	'@',
	'\\[',
	'\\\\',
	'\\]',
	'\\^',
	'_',
	'`',
	'{',
	'\\|',
	'}',
	'~'
].join('|');

const charsets = {
	upperCase: {
		explain: function () {
			return {
				message: 'upper case letters (A-Z)',
				code: 'upperCase'
			};
		},
		test: function (password) {
			return /[A-Z]/.test(password);
		}
	},
	lowerCase: {
		explain: function () {
			return {
				message: 'lower case letters (a-z)',
				code: 'lowerCase'
			};
		},
		test: function (password) {
			return /[a-z]/.test(password);
		}
	},
	specialCharacters: {
		explain: function () {
			return {
				message: 'special characters (e.g. !@#$%^&*)',
				code: 'specialCharacters'
			};
		},
		test: function (password) {
			return specialCharactersRegexp.test(password);
		}
	},
	numbers: {
		explain: function () {
			return {
				message: 'numbers (i.e. 0-9)',
				code: 'numbers'
			};
		},
		test: function (password) {
			return /\d/.test(password);
		}
	}
};
var specialCharactersRegexp = new RegExp(specialCharacters);

var upperCase = charsets.upperCase;
var lowerCase = charsets.lowerCase;
var numbers = charsets.numbers;
var specialCharacter = charsets.specialCharacters;

const policies = {
	none: {
		introText: 'Should be at least 1 character of any type.',
		length: { minLength: 1 }
	},
	low: {
		introText: 'Should be at least 8 characters',
		length: { minLength: 8 }
	},
	fair: {
		introText: 'Should be at least 8 characters including a lower-case letter, an upper-case letter, and a number.',
		length: { minLength: 8 },
		contains: {
			expressions: [lowerCase, upperCase, numbers]
		}
	},
	good: {
		introText:
			'Should be at least 8 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).',
		length: { minLength: 8 },
		containsAtLeast: {
			atLeast: 3,
			expressions: [lowerCase, upperCase, numbers, specialCharacter]
		}
	},
	excellent: {
		introText:
			'Should be at least 10 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*). Not more than 2 identical characters in a row (such as 111 is not allowed).',
		length: { minLength: 10 },
		containsAtLeast: {
			atLeast: 3,
			expressions: [lowerCase, upperCase, numbers, specialCharacter]
		},
		identicalChars: { max: 2 }
	}
};

export default function () {
	return {
		policies,
		validate: (policy, password) => {
			const result = [];
			if (password.length < policy.length.minLength) {
				result.push(`at least ${policy.length.minLength} characters`);
			}
			const expressions = policy.containsAtLeast?.expressions.map(expression => {
				return {
					validated: expression.test(password),
					explanation: expression.explain()
				};
			});
			const verifiedCount = expressions?.reduce((val, ex) => {
				return val + !!ex.verified;
			}, 0);
			const verified = verifiedCount >= policy.containsAtLeast?.atLeast;

			if (!verified) {
				expressions?.forEach(e => {
					if (!e.validated) {
						result.push(e.explanation.message);
					}
				});
			}
			return result;
		}
	};
}
