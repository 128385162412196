<!--eslint-disable vue/no-v-text-v-html-on-component-->
<template>
	<component
		:is="element"
		ref="iconContainer"
		v-html="require(`./../assets/icons/${icon}.svg?raw`)"
		:class="elementClass"
	/>
</template>

<script>
import { computed, defineComponent, onMounted, onUpdated, ref } from 'vue';

const recursivelyRemoveAttribute = (el, attribute, hasAttributes) => {
	if (el) {
		if (el.hasAttribute(attribute)) {
			if (el.attributes[attribute].value === 'none') {
				if (el.hasChildNodes()) el.removeAttribute(attribute);
			} else {
				hasAttributes.value[attribute] = true;
				el.removeAttribute(attribute);
			}
		}
		Array.prototype.forEach.call(el.children, child => {
			recursivelyRemoveAttribute(child, attribute, hasAttributes);
		});
	}
};

export default defineComponent({
	props: {
		icon: {
			type: String,
			required: true
		},
		element: {
			type: String,
			default: 'div'
		},
		growByHeight: {
			type: Boolean,
			default: true
		}
	},
	setup: (p) => {
		const iconContainer = ref();
		const hasAttributes = ref({
			fill: false,
			stroke: false
		});
		const elementClass = computed(() => ({
			'svg-icon': true,
			'svg-icon--filled': hasAttributes.value.fill,
			'svg-icon--stroked': hasAttributes.value.stroke,
			'svg-icon--loader': p.icon === 'loader',
			'svg-icon--button': p.element === 'button'
		}));
		const cleanSvgElement = () => {
			const svgElement = iconContainer.value?.firstElementChild;
			if (svgElement?.nodeName === 'svg') {
				// use `viewBox` attribute to get the svg's inherent width and height
				const viewBox = svgElement
					.getAttribute('viewBox')
					.split(' ')
					.map(n => Number(n));
				const widthToHeight = viewBox[2] / viewBox[3];

				// recursively remove all fill attribute of element and its nested children
				recursivelyRemoveAttribute(svgElement, 'fill', hasAttributes);
				// recursively remove all stroke attribute of element and its nested children
				recursivelyRemoveAttribute(svgElement, 'stroke', hasAttributes);

				// set width and height relative to font size
				// if growByHeight is true, height set to 1em else width set to 1em and remaining is calculated based on widthToHeight ratio
				if (p.growByHeight) {
					svgElement.setAttribute('height', '1em');
					svgElement.setAttribute('width', `${widthToHeight.toFixed(2)}em`);
				} else {
					svgElement.setAttribute('width', '1em');
					svgElement.setAttribute('height', `${(1 / widthToHeight).toFixed(2)}em`);
				}
			}
		};

		onMounted(cleanSvgElement);
		onUpdated(cleanSvgElement);

		return {
			iconContainer,
			elementClass
		};
	}
});
</script>

<style lang="scss">
.svg-icon {
	display: inline-block;
	line-height: 1;

	&--button {
		border-radius: 50%;

		&:focus {
			outline: 4px solid $color-outline-focus;
		}
	}

	&--stroked {
		fill: transparent;
		stroke: $color-icon;
	}
	&--filled {
		fill: $color-icon;
		stroke: none;
	}

	&--loader {
		animation: rotation 1.5s infinite linear;
	}

	svg {
		display: block;
	}
}
</style>
