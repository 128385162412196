import { cookies } from '@/utils';

export default function useHubspot() {
	if (!window._hsq) {
		const scriptElement = document.createElement('script');
		scriptElement.setAttribute('src', '//js-eu1.hs-scripts.com/25051699.js');
		scriptElement.async = true;
		document.head.appendChild(scriptElement);
	}
	async function createContact({ email, firstName, lastName, company }) {
		{
			const data = {
				submittedAt: Date.now(),
				fields: [
					{
						name: 'email',
						value: email
					},
					{
						name: 'firstname',
						value: firstName
					},
					{
						name: 'lastname',
						value: lastName
					},
					{
						name: 'company',
						value: company
					}
				],
				context: {
					hutk: cookies()?.hubspotutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
					pageUri: window.location.href,
					pageName: 'Create HS Account'
				},
				legalConsentOptions: {
					// Include this object when GDPR options are enabled
					consent: {
						consentToProcess: true,
						text: 'I agree to allow Example Company to store and process my personal data.',
						communications: [
							{
								value: true,
								subscriptionTypeId: 999,
								text: 'I agree to receive marketing communications from Example Company.'
							}
						]
					}
				}
			};
			return await fetch(
				'https://api.hsforms.com/submissions/v3/integration/submit/25051699/6125b4fe-0d73-4926-8fcc-b0d6cef7755c',
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					},
					method: 'POST',
					body: JSON.stringify(data)
				}
			);
		}
	}
	return { createContact };
}
