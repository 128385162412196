<template>
	<div class="environment-delete-modal">
		<div class="environment-delete-modal__content bg-white card-1 pa-32" v-click-outside="close">
			<h2 class="font-h2 mb-16">Do you really want to delete {{ environmentName }}?</h2>
			<div class="notification notification--warning">
				<svg-icon icon="warning" />
				This action cannot be undone!
				The Avassa Control Tower instance and the data connected to it will be deleted and this cannot be reverted.
			</div>
			<InputField
				ref="nameInputElement"
				class="mt-32"
				name="Please confirm by typing the name of the environment to be deleted"
				v-model="inputName"
				placeholder="Environment name"
				:length="20"
				@keyup.enter="matchName"
				data-cy="environment-delete-modal__confirm"
			/>
			<div class="environment-delete-modal__buttons">
				<button type="button" class="btn btn--secondary" @click="close">
					Cancel
				</button>
				<button class="btn btn--alert" :disabled="inputName !== environmentName" @click="matchName" data-cy="environment-delete-modal__delete">
					Delete
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import InputField from '@/components/InputField';
import SvgIcon from '@/components/SvgIcon';
import { ref, onMounted } from 'vue';
import useEnvironments from '@/modules/useEnvironments';

export default {
	components: {
		SvgIcon,
		InputField
	},
	emits: ['close'],
	props: ['environmentName', 'environmentId', 'show'],
	setup(props, { emit }) {
		const { deleteEnvironment } = useEnvironments();
		const inputName = ref('');
		const nameInputElement = ref(null);

		onMounted(
			()  => {
				nameInputElement.value?.focusInput();
			}
		);

		const close = () => {
			emit('close');
		};

		const matchName = async () => {
			if (inputName.value === props.environmentName) {
				await deleteEnvironment(props.environmentId);
				close();
			}
		};

		return {
			name,
			matchName,
			inputName,
			close,
			nameInputElement
		};
	}
};
</script>
<style lang="scss" scoped>
.environment-delete-modal {
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba($color-black, .5);

	&__content {
		max-width: 500px;
	}

	&__buttons {
		display: flex;
		justify-content: flex-end;
		margin-top: $spacing;

		> :last-child {
			margin-left: $spacing;
		}
	}
}
</style>
