<template>
	<div class="mail-not-invited card-1">
		<h1 class="mb-16">Your e-mail is not registered</h1>
		<p>
			Thanks for wanting to create an account in the Avassa Cloud Platform.
		</p>
		<p>
			However, you need to
			<a href="https://info.avassa.io/free-trial" class="font-bold">register first</a>.
		</p>

		<h2 class="font-h4">Need help?</h2>
		<p>
			Contact us at
			<a href="mailto:support@avassa.io" class="font-bold">support@avassa.io</a>.
		</p>
		<div class="align-center pt-16">
			<button type="button" class="btn" @click="$router.go(0)">Try again</button>
		</div>
	</div>
</template>
<script>
</script>
<style lang="scss" scoped>
.mail-not-invited {
	max-width: 500px;
	margin: 0 auto;
	background-color: $color-white;
	padding: $spacing-xlarge;

	p {
		margin-bottom: $spacing;
	}
}
</style>
