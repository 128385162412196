<template>
	<div v-if="sessionTimeout" class="session-overlay">
		<div class="session-timeout-info card-1 bg-white">
			<div class="content pt-64 pb-64 font-emphasize">
				<h1 class="pb-16">Session timeout</h1>
				<p class="font-body pb-32">Your session has expired while you were idle.</p>
				<button class="btn btn--large" v-wave @click="reload">
					Reload page
					<svg-icon icon="refresh" />
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import useUser from '@/modules/useUser';
import SvgIcon from '@/components/SvgIcon';
export default {
	components: { SvgIcon },

	setup() {
		const { sessionTimeout } = useUser();
		const reload = () => {
			window.location.reload();
		};
		return { sessionTimeout, reload };
	}
};
</script>

<style lang="scss" scoped>
.content {
	max-width: 450px;
	margin: 0 auto;
}
.session-timeout-info {
	width: 100%;
	max-height: 430px;
	max-width: 750px;
}
.session-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	height: 100vh;
	width: 100vw;
	background-color: rgba(#000, 0.5);
	position: fixed;
}
</style>
