<template>
	<div class="main korvilutt-background">
		<div class="logo-container">
			<img src="@/assets/avassa-logo-white.svg" width="200" height="34" alt="Avassa" />
		</div>
		<div
			class="card-1 bg-white pt-48 flex-column"
			style="max-width: 682px; min-height: 432px; justify-content: space-between"
		>
			<transition name="fade" mode="out-in">
				<div class="pl-48 pr-48" v-if="!mailSent">
					<div class="font-h2">Forgot your password?</div>
					<div class="font-body" style="padding-top: 16px">
						Enter your email to receive instructions on how to reset your password.
					</div>
					<div class="form-group mt-24">
						<InputField name="Email address" class="pt-16" v-model="email" type="email" style="max-width: 400px" />
						<button id="sendResetBtn" type="button" class="btn reset-btn" @click="rPassword(email)">Reset</button>
					</div>
				</div>
				<div class="pl-48 pr-48" v-else>
					<div class="font-h2">We’ve sent you an email</div>
					<div class="font-emphasize">Please check your inbox</div>
					<div class="font-body" style="padding-top: 16px">
						To reset your password, follow the instructions in the email from hello@avassa.io
					</div>
				</div>
			</transition>
			<div class="bottom pl-48 pr-48 pt-32 pb-48">
				<a :href="`${apiUrl()}/login`">Go back to sign-in</a>
			</div>
		</div>
	</div>
</template>
<script>
import InputField from '@/components/InputField';
import useUser from '@/modules/useUser';
import { ref } from 'vue';
import { apiUrl } from '@/utils';
export default {
	components: { InputField },
	setup() {
		const mailSent = ref(false);
		const email = ref('');
		const { resetPassword } = useUser();

		const rPassword = email => {
			resetPassword(email);
			mailSent.value = true;
		};
		return { rPassword, email, mailSent, apiUrl };
	}
};
</script>
<style lang="scss" scoped>
.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.form-group {
	flex-wrap: nowrap;
}
.reset-btn {
	max-height: 40px;
	display: flex;
	justify-content: center;
	align-self: end;
	margin-left: $spacing;
}
.bottom {
	border-top: 1px solid $color-gray-400;
}
a {
	font-size: 14px;
	color: $color-gray-900;
}
</style>
