<template>
	<span
		:class="['status-badge', 'capitalize-first', `status-badge--${status.toLowerCase()}`]"
		:data-cy="`status-badge--${status.toLowerCase()}`"
	>
		<slot></slot>
		<svg-icon v-if="['creating','created','deleting'].includes(status.toLowerCase())" icon="loader" class="status-badge__icon" />
	</span>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";

export default {
	components: {
		SvgIcon
	},
	props: {
		status: String
	}
};
</script>

<style lang="scss" scoped>
.status-badge {
	display: inline-block;
	border-radius: 50px;
	padding: 4px 12px;
	font-size: 14px;
	font-weight: 600;
	background-color: $color-background-disabled;
	color: $color-text-disabled;

	&--created,
	&--ready {
		background-color: $color-success-light;
		color: $color-success;
	}
	&--creating {
		background-color: $color-warning-light;
		color: $color-warning;
	}
	&--deleting,
	&--failed {
		background-color: $color-error-light;
		color: $color-error;
	}

	&__icon {
		margin: -2px -2px 0 4px;
		vertical-align: middle;
		font-size: $icon-size-standard;
		stroke: $color-text-disabled;

		.status-badge--created &,
		.status-badge--ready & {
			stroke: $color-success;
		}
		.status-badge--creating & {
			stroke: $color-warning;
		}
		.status-badge--deleting &,
		.status-badge--failed & {
			stroke: $color-error;
		}
	}
}
</style>
