import { createApp } from 'vue';

import VWave from 'v-wave';
import App from './App.vue';
import router from './router/router';
import '@/assets/scss/main.scss';
import '@/assets/css/main.scss';
import useUser from '@/modules/useUser';
import { clickOutside } from '@/utils';

const { init } = useUser();
init();

createApp(App).use(router).use(VWave).directive('click-outside', clickOutside).mount('#app');
