<template>
	<div class="full-width" :class="{ 'validation-error': !!error }">
		<label :for="label" class="input-label">
			{{ label }}
			<span v-if="optional" class="font-semi-bold">(optional)</span>
			<span v-if="required" class="font-semi-bold text-accent">*</span>
		</label>
		<input
			ref="inputElement"
			:id="label"
			class="input font-body full-width"
			:type="type"
			:placeholder="placeholder"
			:value="modelValue"
			:maxlength="length"
			@input="$emit('update:modelValue', $event.target.value)"
		/>
		<div v-if="!!error" class="font-error font-small">{{ error }}</div>
		<div v-else-if="!!hint" class="font-small">{{ hint }}</div>
	</div>
</template>
<script>
import { ref } from 'vue';

export default {
	props: {
		name: String,
		type: String,
		placeholder: String,
		modelValue: [Number, String],
		optional: Boolean,
		hint: String,
		validate: Function,
		required: Boolean,
		error: String,
		length: Number
	},
	setup(props) {
		const validationResult = ref('');
		const inputElement = ref(null);

		const validateValue = val => {
			if (val !== '' && props.validate) {
				validationResult.value = props.validate(val);
			} else {
				validationResult.value = '';
			}
		};
		const focusInput = () => {
			inputElement.value?.focus();
		};
		return { label: props.name, validateValue, validationResult, inputElement, focusInput };
	}
};
</script>
<style lang="scss" scoped>
.input-label {
	display: inline-block;
	font-size: $font-size-standard;
	margin-bottom: 5px;
}
.input {
	box-sizing: border-box;
	border: 1px solid $color-border;
	border-radius: $border-radius;
	padding: $spacing-small $spacing-minus;
	outline: none;
	box-shadow: inset 0 0 0 0;
	font-size: $font-size-body;
	font-weight: $font-weight-regular;
	line-height: 22px;

	&:focus {
		border: 1px solid $color-border-focus;
	}

	.validation-error & {
		border: 1px solid $color-border-error;
	}
}
</style>
