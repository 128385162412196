<template>
	<div
		class="toggle-field"
		:class="{
			'toggle-field--error': error,
			'toggle-field--disabled': disabled,
			[`toggle-field--${size}`]: size,
			'toggle-field--left-selected': leftSelected,
			'toggle-field--right-selected': rightSelected
		}"
	>
		<label v-if="!showLabelsInline && leftLabel" class="toggle-field__label toggle-field__label--left">
			{{ leftLabel }}
			<input
				type="radio"
				:name="name"
				:value="leftValue"
				:disabled="disabled"
				:checked="(modelValue || value) === leftValue"
				class="toggle-field__input"
				@change="
					e => {
						if (!disabled && e.currentTarget.checked) $emit('update:modelValue', leftValue);
					}
				"
			/>
		</label>
		<button
			type="button"
			:disabled="disabled"
			class="toggle-field__switch switch"
			:class="{
				'switch--on': rightSelected,
				'switch--off': leftSelected,
				'switch--text': showLabelsInline,
				'switch--traffic': trafficLights,
				'switch--always-on': alwaysOn,
				[`switch--${size}`]: size
			}"
			@click.prevent="handleSwitchClick"
			:title="(!modelValue && nulledTitle) || ''"
		>
			{{ showLabelsInline ? (leftSelected ? leftLabel : rightSelected ? rightLabel : '') : '' }}
		</button>
		<label
			v-if="!showLabelsInline && rightLabel"
			class="toggle-field__label toggle-field__label--right"
		>
			{{ rightLabel }}
			<input
				type="radio"
				:name="name"
				:value="rightValue"
				:disabled="disabled"
				:checked="(modelValue || value) === rightValue"
				class="toggle-field__input"
				@change="
					e => {
						if (!disabled && e.currentTarget.checked) $emit('update:modelValue', rightValue);
					}
				"
			/>
		</label>
	</div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { v4 as uuid } from 'uuid';

export default defineComponent({
	props: {
		modelValue: [String, Number],
		value: [String, Number],
		leftValue: [String, Number],
		rightValue: [String, Number],
		leftLabel: String,
		rightLabel: String,
		showLabelsInline: Boolean,
		nullable: Boolean,
		nulledTitle: {
			type: String,
			default: 'Inherited'
		},
		error: Boolean,
		size: String,
		trafficLights: Boolean,
		alwaysOn: Boolean,
		disabled: Boolean
	},
	setup: (p, { emit }) => {
		// eslint-disable-next-line
		let previousValue = p.modelValue;

		const handleSwitchClick = e => {
			if (!p.disabled) {
				if (e.clientX && e.clientY) {
					if (p.nullable && p.modelValue) {
						const switchThird = ((e.currentTarget && e.curentTarget.offsetWidth) || 0) / 3;

						if (e.offsetX < switchThird) {
							if (p.modelValue !== p.leftValue) emit('update:modelValue', p.leftValue);
						} else if (e.offsetX > switchThird * 2) {
							if (p.modelValue !== p.rightValue) emit('update:modelValue', p.rightValue);
						} else {
							emit('update:modelValue', null);
						}
					} else {
						if (e.offsetX < (e.currentTarget && e.currentTarget.offsetWidth || 0) / 2) {
							if (p.modelValue !== p.leftValue) emit('update:modelValue', p.leftValue);
						} else {
							if (p.modelValue !== p.rightValue) emit('update:modelValue', p.rightValue);
						}
					}
				} else if (p.nullable) {
					let newValue = null;
					if (!p.modelValue) {
						newValue = previousValue === p.rightValue ? p.leftValue : p.rightValue;
					}
					emit('update:modelValue', newValue);
				} else {
					emit('update:modelValue', p.modelValue === p.rightValue ? p.leftValue : p.rightValue);
				}
				previousValue = p.modelValue;
			}
		};

		return {
			name: uuid(),
			leftSelected: computed(() => (p.modelValue || p.value) === p.leftValue),
			rightSelected: computed(() => (p.modelValue || p.value) === p.rightValue),
			handleSwitchClick,
			previousValue
		};
	}
});
</script>

<style lang="scss" scoped>
.toggle-field {
	margin-bottom: $spacing-minus;

	&__label {
		display: inline-block;

		&--left {
			margin-right: $spacing-small;
		}
		&--right {
			margin-left: $spacing-small;
		}

		.toggle-field--disabled & {
			color: $color-text-light;
		}
	}

	&__switch {
		&:disabled {
			cursor: default;
		}

		&:not(:disabled) {
			&:not(.switch--traffic) {
				background-color: $color-gray-500;
			}

			&:hover,
			&:focus {
				outline: 4px solid $color-outline-focus;
			}

			&::after {
				opacity: 0.5;
			}

			&.switch--on,
			&.switch--always-on {
				&:not(.switch--traffic) {
					background-color: $color-primary-brand;
				}
				&::after {
					opacity: 1;
				}
			}

			&.switch--off {
				&:not(.switch--always-on):not(.switch--traffic) {
					background-color: $color-gray-800;
				}
				&::after {
					background-color: $color-white;
					opacity: 1;
				}
			}
		}

		.toggle-field--error & {
			background-color: $color-error;
		}
	}

	&__input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
		margin: 0;
	}

	&--small {
		font-size: $font-size-secondary;
	}
}
</style>
