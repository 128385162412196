<template>
	<div class="action-menu">
		<button
			v-if="buttonText"
			class="action-menu__trigger action-menu__trigger--text btn btn--secondary"
			:disabled="disabled"
			@click.stop="onTriggerClick"
		>
			{{ buttonText }} <svg-icon :icon="isOpen ? 'chevron-up' : 'chevron-down'" />
		</button>
		<svg-icon
			v-else
			element="button"
			type="button"
			:icon="isOpen ? 'close' : 'more-vertical'"
			title="Toggle menu"
			class="action-menu__trigger text-btn"
			:disabled="disabled"
			@click.stop="onTriggerClick"
		/>
		<transition name="popup-menu">
			<ul v-if="isOpen" class="action-menu__items" v-click-outside="onClickOutside">
				<li v-for="{ title, event, icon, type, cy } in items" :key="title" class="action-menu__item">
					<button
						class="action-menu__button"
						type="button"
						@click.stop="onItemClick(event)"
						:class="{ [`action-menu__button--${type}`]: type }"
						:data-cy="cy"
					>
						<svg-icon v-if="icon" :icon="icon" class="action-menu__item-icon" />
						{{ title }}
					</button>
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';

export default defineComponent({
	name: 'ActionMenu',
	components: {
		SvgIcon
	},
	props: {
		buttonText: String,
		items: {
			type: Array,
			required: true
		},
		disabled: Boolean
	},

	setup: (p, { emit }) => {
		const isOpen = ref(false);

		const onTriggerClick = () => {
			isOpen.value = !isOpen.value;
			emit(isOpen.value ? 'open' : 'closed');
		};

		const onItemClick = (event) => { isOpen.value = false; emit(event); }

		const onClickOutside = () => {
			if (isOpen.value) {
				isOpen.value = false;
				emit('closed');
			}
		};

		return {
			isOpen,
			onTriggerClick,
			onClickOutside,
			onItemClick
		};
	}
});
</script>

<style lang="scss" scoped>
.action-menu {
	position: relative;

	&__trigger {
		padding: $spacing-small;
		font-size: $icon-size-large;
		fill: $color-black;
		transition: all duration(standard) ease;

		&--text.btn {
			font-size: $font-size-small;

			.svg-icon {
				font-size: $icon-size-standard;
				margin-left: $spacing-small;
			}
		}
	}

	&__items {
		@extend %flex-parent-col;
		transform-origin: top right;
		position: absolute;
		z-index: $zindex-active-elements;
		overflow: hidden;
		min-width: 180px;
		right: 0;
		margin-top: $spacing-xsmall;
		border: 1px solid $color-border;
		border-radius: $border-radius;
		box-shadow: $box-shadow-small;

		&:focus-visible {
			outline-color: $color-border-focus;
		}
	}

	&__item:not(:last-child) {
		border-bottom: 1px solid $color-border;
	}

	&__button {
		width: 100%;
		background: $color-white;
		padding: $spacing-small $spacing-minus;
		cursor: pointer;
		transition: background duration(standard) ease;

		.action-menu__item:first-child & {
			padding-top: $spacing-minus;
		}
		.action-menu__item:last-child & {
			padding-bottom: $spacing-minus;
		}

		&:hover {
			background: $color-background-hover;
		}

		&--alert {
			color: $color-error;

			.svg-icon {
				&--filled {
					fill: $color-error;
				}
				&--stroked {
					stroke: $color-error;
				}
			}
		}
	}

	&__item-icon {
		font-size: $icon-size-standard;
		vertical-align: middle;
		margin-bottom: 3px;
		margin-right: $spacing-xsmall;
	}
}
</style>
