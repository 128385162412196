<template>
	<div class="create-environment">
		<h2 class="font-h1">Create new {{ category === 'production' ? 'production' : '14-day trial' }} environment</h2>
		<div class="grid">
			<div class="create-environment__create-new">
				<name-environment :category="category" />
				<choose-category v-model:type="category" :is-contracted-customer="isContractedCustomer" />
			</div>
			<div class="create-environment__info">
				<h3>What is an environment?</h3>

				<p>There are two kinds of environments:</p>
				<ul class="list">
					<li>Trial environments are free trial accounts including 2 virtual edge sites to get started with.</li>
					<li>Production environments are for commercial use and do not provide pre-populated sites.</li>
				</ul>

				<p>An environment is a combination of the following:</p>
				<ul class="list">
					<li>
						An instance of the Control Tower to manage your edge sites and applications reachable at a URL on the form [environment-name].[subdomain].avassa.net,
						where the subdomain part is the information entered when you registered your account.
						You can not modify the name of an environment since it is used for DNS configuration.
					</li>
					<li>
						In the case of a trial account, the environment will include 2 virtual sites, with one host each, to get you going.
						The sites are already configured and available in Control Tower when you log in.
						You may also add external hosts to your trial environment.
						Please refer to the
						<a href="https://docs.avassa.io/tutorials/adding-site" target="_blank" class="text-btn">Add your first site <svg-icon icon="open-in-new" /></a>
						tutorial for adding additional hosts.
					</li>
				</ul>

				<p>
					You may want different environments for different projects, e.g. separate production and staging environments.
					For this purpose, you can create several environments from the Avassa Cloud Platform.
					Trial accounts are limited to two environments.
				</p>

				<p>
					If you delete an environment it will be visible for a couple of hours in your list of environments.
					This is to indicate it may take a while to remove the environment name from DNS.
				</p>
			</div>
		</div>
	</div>
</template>
<script>
import { ref } from 'vue';
import NameEnvironment from '@/views/CreateEnvironment/NameEnvironment';
import ChooseCategory from '@/views/CreateEnvironment/ChooseCategory';
import useOrganization from "@/modules/useOrganization";
import useUser from "@/modules/useUser";
import SvgIcon from "@/components/SvgIcon";
export default {
	components: { ChooseCategory, NameEnvironment, SvgIcon },
	setup() {
		const {
			organization: { value }
		} = useOrganization();
		const { userState } = useUser();

		const isContractedCustomer = value?.eligibleLevel === 'PRODUCTION';

		const category = ref(isContractedCustomer ? 'production' : 'trial');

		const mailTo = `mailto:hello@avassa.com?subject=Requesting production access&body=Hello Avassa! %0D%0A %0D%0A I'd like to be able to create production type environments! %0D%0A %0D%0A Best Regards, %0D%0A ${userState.user.name} `;

		return {
			category,
			isContractedCustomer,
			mailTo
		};
	}
};
</script>
<style lang="scss" scoped>
.create-environment {
	&__create-new {
		grid-column: 1/13;
		grid-row: 1;

		@media all and (min-width: 70em) {
			grid-row: 1;
			grid-column: 1/8;
		}
	}

	h2 {
		margin-bottom: $spacing;
	}

	&__info {
		grid-column: 1/13;
		grid-row: 2;

		border-radius: $border-radius;
		border: 1px solid $color-border;
		padding: $spacing-xlarge;

		@media all and (min-width: 70em) {
			grid-row: 1;
			grid-column: 8/13;
		}

		p,
		h3 {
			margin-bottom: $spacing-minus;
		}

		ul {
			margin: 0 0 $spacing-minus 0;
			padding-left: 20px;
		}

		li {
			margin-bottom: $spacing-xsmall;

			.text-btn {
				padding: 0;
				line-height: inherit;
				vertical-align: unset;
				white-space: nowrap;
			}
		}
	}
}
</style>