<template>
	<div
		class="environment"
		:class="{'environment--selected': selected, 'environment--ready': selected && environment.status === 'READY'}"
		:data-cy="selected && environment.status === 'READY' ? 'environment--ready' : ''"
	>
		<div class="environment__header">
			<div class="environment__heading">
				<environment-rename
					v-if="showEditName || savingName"
					:model-value="environment.name"
					@save="rename"
					@cancel="onRenameCancel"
				/>
				<h3 v-else class="font-h2">{{ environment.name }}</h3>

				<p>{{ environment.domain }}</p>
			</div>
			<action-menu
				v-if="!(showEditName || savingName) && ['READY', 'FAILED'].includes(environment.status)"
				class="environment__actions"
				:items="[
						//{ title: 'Rename', event: 'rename', icon: 'edit', cy: 'environment__action-rename' },
						{ title: 'Delete', event: 'delete', icon: 'trash', type: 'alert', cy: 'environment__action-delete' }
					]"
				:disabled="disableActions"
				@rename="showEditName = true"
				@delete="showDeleteModal = true"
				data-cy="environment__actions"
			/>
		</div>
		<table class="full-width">
			<thead>
			<tr>
				<th class="font-pretitle">Date Created</th>
				<th class="font-pretitle">Status</th>
				<th class="font-pretitle">Category</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			<tr>
				<td>{{ dayjs(environment.createdAt).format('YYYY-MM-DD') }}</td>
				<td><status-badge :status="environment.status">{{ environment.status !== "CREATED" ? environment.status : 'FINALIZING' }}</status-badge></td>
				<td class="capitalize-first">{{ environment.category }}</td>
				<td><button
					class="environment__openCT btn"
					:disabled="environment.status !== 'READY'"
					v-wave
					@click="openControlTower(environment.url)"
					data-cy="environment__open-control-tower"
				>
					Open Control Tower
					<svg-icon icon="chevron-right" />
				</button></td>
			</tr>
			</tbody>
		</table>

		<transition name="fade" mode="out-in">
			<environment-delete-modal
				v-if="showDeleteModal"
				:environmentName="environment.name"
				:environmentId="environment.environmentId"
				@close="showDeleteModal = false"
			/>
		</transition>
	</div>
</template>
<script>
import EnvironmentRename from '@/components/EnvironmentRename';
import EnvironmentDeleteModal from '@/components/EnvironmentDeleteModal';
import StatusBadge from '@/components/StatusBadge';
import SvgIcon from '@/components/SvgIcon';
import ActionMenu from '@/components/ActionMenu';
import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import useEnvironments from '@/modules/useEnvironments';
import useOrganization from "@/modules/useOrganization";
import dayjs from "dayjs";

export default {
	components: {
		EnvironmentRename,
		EnvironmentDeleteModal,
		StatusBadge,
		SvgIcon,
		ActionMenu
	},
	props: {
		environment: {
			type: Object,
			required: true
		},
		selected: Boolean
	},
	setup(props) {
		const environment = toRef(props, 'environment');
		const router = useRouter();
		const { renameEnvironment } = useEnvironments();
		const savingName = ref(false);

		const { organization } = useOrganization();

		const showDeleteModal = ref(false);
		const showEditName = ref(false);

		const openControlTower = url => {
			window.open(`${url}/login?tenant=${organization.value.edgeCloudManager}&oidc=avassa-cloud-account`);
		}

		const disableActions = computed(() => ['CREATING', 'DELETING', 'DELETED'].includes(environment.value.status));

		const onRenameCancel = () => {
			showEditName.value = false;
		};
		const rename = async environmentName => {
			savingName.value = true;
			showEditName.value = false;

			const success = await renameEnvironment({
				environmentId: environment.value.environmentId,
				environmentName
			});
			if (success) {
				router.push({ params: { name: environmentName } });
			}
			savingName.value = false;
		};

		return {
			rename,
			onRenameCancel,
			showEditName,
			showDeleteModal,
			savingName,
			disableActions,
			openControlTower,
			dayjs
		};
	}
};
</script>
<style lang="scss" scoped>
.environment {
	border: 1px solid $color-border;
	border-radius: $border-radius;
	padding: $spacing;

	&--selected {
		border-color: $color-primary-brand;
	}

	&--ready {
		border-color: $color-success;
	}

	&__header {
		@extend %flex-parent-row;
		justify-content: space-between;
		margin-bottom: $spacing;
	}

	&__heading {
		flex-grow: 1;

		p {
			color: $color-text-secondary;
		}
	}

	table {
		border-collapse: collapse;

		th {
			display: table-cell;

			&:nth-child(-n+3) {
				width: 20%;
			}
		}

		td {
			&:last-child {
				text-align: right;
			}
		}
	}

	&__openCT {
		display: inline-block;
	}
}
</style>
