<template>
	<div class="environments grid">
		<h2 class="font-h1">Environments</h2>
		<div class="environments__list">
			<ul>
				<li v-for="item in sortedEnvironments" :key="item.platformId" class="environments__list__item">
					<EnvironmentItem :environment="item" :selected="item.name === selectedEnvironment?.name" />
				</li>
			</ul>
			<div class="environments__create-new">
				<router-link to="/create-environment" class="text-btn">
					<svg-icon icon="plus" class="sidebar__menu-item__icon" /> Create new environment
				</router-link>
			</div>
		</div>
		<div class="environments__feedback-container">
			<AllSet
				v-if="!selectedEnvironment || selectedEnvironment.status === 'READY'"
				:environment-selected="!!selectedEnvironment"
			/>
			<EnvInProgress v-else-if="selectedEnvironment?.status === 'CREATING' || selectedEnvironment?.status === 'CREATED'" />
		</div>
	</div>
</template>
<script>
import useEnvironments from '@/modules/useEnvironments';
import EnvironmentItem from '@/components/EnvironmentItem';
import SvgIcon from '@/components/SvgIcon';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import EnvInProgress from '@/views/Environments/EnvInProgress';
import AllSet from '@/views/Environments/AllSet';

export default {
	components: {
		EnvironmentItem,
		SvgIcon,
		EnvInProgress,
		AllSet
	},
	setup() {
		const { environments } = useEnvironments();

		const sortedEnvironments = computed(() =>
			[...environments.value].sort((a,b) => b.createdAt > a.createdAt ? 1 : -1)
		);

		const route = useRoute();
		const selectedEnvironment = computed(() => environments.value.find((env) => env.name === route.query?.name));

		return {
			sortedEnvironments,
			selectedEnvironment
		};
	}
};


</script>
<style lang="scss" scoped>
.environments {
	&__list {
		grid-column: 1/13;
		grid-row: 3;

		@media all and (min-width: 70em) {
			grid-row: 2;
			grid-column: 1/8;
		}

		&__item {
			margin-bottom: $spacing;
		}
	}

	&__create-new {
		grid-column: 1/12;
		@media all and (min-width: 70em) {
			grid-column: 1/8;
		}
	}

	&__feedback-container {
		grid-column: 1/13;
		grid-row: 2;

		@media all and (min-width: 70em) {
			grid-row: 2;
			grid-column: 8/13;
		}
	}
}
.trial-box {
	border-radius: $border-radius;
	border: 1px solid $color-primary-brand;
}
</style>
