<template>
	<div class="create-account">
		<div v-if="notInvited" class="create-account__section">
			<MailNotInvited />
		</div>
		<div v-else-if="accountCreated" class="create-account__section">
			<EmailSent />
		</div>
		<template v-else>
			<div class="create-account__section">
				<img src="@/assets/avassa-logo-blue.svg" alt="Avassa" class="create-account__logo" />
				<div class="create-account__box">
					<h1 class="font-h2">Step 2 of your trial registration</h1>
					<div v-if="alreadyRegistered" class="card-1 warning-message full-width">
						This email is already registered, use
						<router-link to="/environments" class="create-account__inline-link">login</router-link>
						instead.
					</div>
					<div v-else-if="serverError" class="card-1 error-message full-width">
						{{ serverError.description }}
					</div>
					<p v-else class="body">
						You have been registered for a trial account.<br />
						Please create an account here.
					</p>

					<InputField
						type="email"
						name="Email"
						v-model="username"
						required
						:error="errors.email"
						class="mb-16"
						@keyup="
							() => {
								if (errors.email) validateEmailAddress();
							}
						"
						@focusout="validateEmailAddress"
					/>
					<div class="create-account__name mb-16">
						<InputField
							type="text"
							name="First name"
							v-model="givenName"
							required
							:error="errors.givenName"
							@keyup="
								() => {
									if (errors.givenName) validateGivenName();
								}
							"
							@focusout="validateGivenName"
						/>
						<InputField
							type="text"
							name="Last name"
							v-model="familyName"
							required
							:error="errors.familyName"
							@keyup="
								() => {
									if (errors.familyName) validateFamilyName();
								}
							"
							@focusout="validateFamilyName"
						/>
					</div>
					<DropdownField
						v-model="country"
						:options="[
							'Select country',
							...Object.values(countries)
								.map(item => item.name)
								.sort()
						]"
						placeholder-item="Select country"
						label="Country"
						required
						:error="errors.country"
						@focusout="validateCountry"
						@change="validateCountry"
					/>
					<InputField
						type="password"
						name="Password"
						v-model="password"
						placeholder="At least 8 characters"
						:error="errors.password"
						required
						class="mb-16"
						@keyup="
							() => {
								if (errors.password) validatePassword();
							}
						"
						@focusout="validatePassword"
					/>
					<div class="flex full-width pt-8">
						<CheckboxField
							v-model="termsAndConditions"
							@change="validateTermsAndConditions"
							:error="errors.termsAndConditions"
							modifier="large"
						>
							I agree to the
							<external-link to="https://avassa.io/legal/" class="create-account__inline-link">
								Avassa Terms of Service and Privacy Policy
							</external-link>
							<span class="pl-4 text-accent">*</span>
						</CheckboxField>
					</div>
					<div class="flex full-width mb-8">
						<CheckboxField v-model="newsLetterSubscription" modifier="large">
							I would like to receive the latest news, product updates, event announcements and such from Avassa. I can
							unsubscribe at any time.
						</CheckboxField>
					</div>
					<LoadingButton @click="executeSignup" :disabled="hasValidationErrors" :loading="loading" data-cy="create-account__submit">Create Account</LoadingButton>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import InputField from '@/components/InputField';
import DropdownField from '@/components/DropdownField';
import LoadingButton from '@/components/LoadingButton';
import CheckboxField from '@/components/CheckboxField';
import EmailSent from '@/views/CreateAccount/EmailSent';
import useUser from '@/modules/useUser';
import usePasswordPolicy from '@/modules/usePasswordPolicy';
import MailNotInvited from '@/views/CreateAccount/MailNotInvited';
import { validateEmail } from '@/utils';
import { computed, ref, reactive } from 'vue';
import ExternalLink from '@/components/ExternalLink';
import { countries } from 'countries-list';

export default {
	components: {
		ExternalLink,
		MailNotInvited,
		InputField,
		DropdownField,
		LoadingButton,
		CheckboxField,
		EmailSent
	},
	setup() {
		const username = ref('');
		const password = ref('');
		const givenName = ref('');
		const familyName = ref('');
		const country = ref('');
		const accountCreated = ref(false);
		const termsAndConditions = ref(false);
		const newsLetterSubscription = ref(false);
		const notInvited = ref(false);
		const loading = ref(false);

		const {
			errors,
			hasValidationErrors,
			validateEmailAddress,
			validateGivenName,
			validateFamilyName,
			validateTermsAndConditions,
			validateCountry,
			validatePassword,
			validateAll
		} = useValidation(username, givenName, familyName, country, password, termsAndConditions);
		const serverError = ref('');

		const alreadyRegistered = computed(() => {
			return serverError.value?.code === 'invalid_signup';
		});

		const handleSignupCallback = e => {
			loading.value = false;
			accountCreated.value = !e;
			notInvited.value = e?.code === 'extensibility_error';
			serverError.value = e;
		};

		const executeSignup = () => {
			if (validateAll()) {
				loading.value = true;
				serverError.value = undefined;
				signup(
					username.value,
					password.value,
					givenName.value,
					familyName.value,
					country.value,
					newsLetterSubscription.value,
					handleSignupCallback
				);
			}
		};

		const { signup } = useUser();

		return {
			countries,
			executeSignup,
			username,
			country,
			password,
			givenName,
			familyName,
			accountCreated,
			termsAndConditions,
			newsLetterSubscription,
			notInvited,
			loading,
			errors,
			hasValidationErrors,
			serverError,
			validateEmailAddress,
			validateGivenName,
			validateFamilyName,
			validatePassword,
			validateCountry,
			validateTermsAndConditions,
			alreadyRegistered
		};
	}
};

const useValidation = (email, givenName, familyName, country, password, termsAndConditions) => {
	const { policies, validate } = usePasswordPolicy();

	const errors = reactive({
		email: '',
		givenName: '',
		familyName: '',
		country: '',
		password: '',
		termsAndConditions: false
	});

	const hasValidationErrors = computed(() => {
		return Object.values(errors).some(value => !!value);
	});

	const validateCountry = () => {
		errors.country = !country.value ? 'You need to select your country' : '';
	};
	const validatePassword = submit => {
		errors.password =
			(password.value || submit) && validate(policies.low, password.value).length !== 0
				? 'Password must be at least 8 characters'
				: '';
	};
	const validateEmailAddress = submit => {
		const invalid = !validateEmail(email.value);
		errors.email = (submit || email.value) && invalid ? 'Not a valid email' : '';
	};

	const validateGivenName = () => {
		errors.givenName = givenName.value.replace(/\s/g, '') === '' ? 'Name cannot be empty' : '';
	};

	const validateFamilyName = () => {
		errors.familyName = familyName.value.replace(/\s/g, '') === '' ? 'Name cannot be empty' : '';
	};

	const validateTermsAndConditions = submit => {
		if (submit || errors.termsAndConditions) {
			errors.termsAndConditions = !termsAndConditions.value;
		}
	};

	const validateAll = () => {
		validateEmailAddress(true);
		validateGivenName();
		validateFamilyName();
		validateCountry();
		validatePassword(true);
		validateTermsAndConditions(true);
		return !hasValidationErrors.value;
	};

	return {
		errors,
		hasValidationErrors,
		validateEmailAddress,
		validateGivenName,
		validateFamilyName,
		validateTermsAndConditions,
		validateCountry,
		validatePassword,
		validateAll
	};
};
</script>
<style lang="scss" scoped>
.create-account {
	min-height: 100%;
	display: flex;
	align-items: stretch;
	justify-items: stretch;

	&__section {
		display: flex;
		padding: 16px;
		flex-grow: 1;
		flex-direction: column;
		justify-content: center;
		background: radial-gradient(88.58% 116.16% at 25.45% -27.67%, #ffffff 0%, #eaeaeb 100%);
	}

	&__box {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		max-width: 500px;
		margin: 0 auto;
		border-radius: 8px;
		padding: 32px;
		background: #fff;
		box-shadow: 0 2px 12px rgba(26, 26, 26, 0.1), 0 16px 24px -2px rgba(26, 26, 26, 0.12);
	}

	&__logo {
		align-self: center;
		width: 300px;
		max-width: 90%;
		margin-bottom: 32px;
	}

	&__name {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		> * {
			width: 49%;
		}
	}

	&__inline-link {
		color: inherit;
	}

	.font-h2 {
		margin: 0 0 16px 0;

		+ .card-1 {
			margin-top: 0;
		}
		+ p {
			margin: 0 0 24px 0;
		}
	}
}
</style>
