<!--
Test form used by Calle & Amy to verify hubspot campaign integrations,
if you read this in the year 2022. You can probably
use your futuristic hover-computer and remove this view.
-->
<template>
	<div class="login-container korvilutt-background" style="text-align: center">
		<div class="card-1 box">
			<div class="padding" style="padding-bottom: 64px">
				<div class="font-h1">Create HS account</div>
			</div>
			<div v-if="serverError && !alreadyRegistered" class="card-1 error-message">
				{{ serverError.description }}
			</div>
			<InputField
				type="email"
				name="Email"
				v-model="username"
				required
				:error="errors.email"
				@focusout="() => validateEmailAddress()"
			/>
			<InputField type="text" name="Firstname" v-model="firstName" required :error="errors.name" />
			<InputField type="text" name="Lastname" v-model="lastName" required :error="errors.name" />

			<InputField
				type="text"
				name="Company"
				v-model="company"
				required
				:error="errors.name"
				@focusout="() => errors.name && validateNameNotEmpty()"
			/>
			<div class="flex full-width pt-8" style="justify-content: left">
				<CheckboxField
					v-model="termsAndConditions"
					@change="validateTermsAndConditions()"
					:error="errors.termsAndConditions"
				>
					By clicking the button I agree to the
					<external-link class="text-black" to="https://avassa.io/legal/"
						>Avassa Terms of Service and Privacy Policy</external-link
					><span class="pl-4 text-accent">*</span>
				</CheckboxField>
			</div>
			<div class="flex full-width" style="justify-content: left">
				<CheckboxField v-model="newsLetterSubscription"
					>Click here to receive the latest news, product updates, event announcements, and other communications from
					Avassa You can unsubscribe anytime
				</CheckboxField>
			</div>
			<div class="flex full-width pb-16" style="justify-content: flex-end">
				<LoadingButton @click="executeSignup" :loading="loading">Create Account</LoadingButton>
			</div>
		</div>
	</div>
</template>
<script>
import InputField from '@/components/InputField';
import CheckboxField from '@/components/CheckboxField';
import usePasswordPolicy from '@/modules/usePasswordPolicy';
import { validateEmail } from '@/utils';
import { computed, ref, reactive } from 'vue';
import ExternalLink from '@/components/ExternalLink';
import useHubspot from '@/modules/useHubSpot';
import LoadingButton from "@/components/LoadingButton";

export default {
	components: {
		LoadingButton,
		ExternalLink,
		InputField,
		CheckboxField
	},
	setup() {
		const username = ref('');
		const password = ref('');
		const firstName = ref('');
		const lastName = ref('');
		const company = ref('');
		const accountCreated = ref(false);
		const termsAndConditions = ref(false);
		const newsLetterSubscription = ref(false);
		const loading = ref(false);
		const {
			errors,
			validateEmailAddress,
			validateNameNotEmpty,
			validateTermsAndConditions,
			validatePassword,
			passwordHint
		} = useValidation(username, firstName, password, termsAndConditions);

		const { createContact } = useHubspot();
		const serverError = ref('');

		const alreadyRegistered = computed(() => {
			return serverError.value?.code === 'invalid_signup';
		});

		const executeSignup = async () => {
			loading.value = true;
			serverError.value = undefined;
			await createContact({
				email: username.value,
				firstName: firstName.value,
				lastName: lastName.value,
				company: company.value
			});
			loading.value = false;
		};
		return {
			executeSignup,
			username,
			password,
			firstName,
			lastName,
			company,
			accountCreated,
			termsAndConditions,
			newsLetterSubscription,
			loading,
			errors,
			serverError,
			validateEmailAddress,
			validateNameNotEmpty,
			passwordHint,
			validatePassword,
			validateTermsAndConditions,
			alreadyRegistered
		};
	}
};

const useValidation = (email, name, password, confirmPassword, termsAndConditions) => {
	const { policies, validate } = usePasswordPolicy();

	const errors = reactive({
		email: '',
		name: '',
		password: '',
		confirmPassword: '',
		termsAndConditions: false
	});

	const hasValidationErrors = () => {
		return Object.entries(errors).some(([, value]) => !!value);
	};

	const passwordHint = 'Password must be at least 8 characters';

	const validatePassword = submit => {
		errors.password =
			(password.value || submit) && validate(policies.low, password.value).length !== 0 ? passwordHint : '';
	};
	const validateEmailAddress = submit => {
		const invalid = !validateEmail(email.value);
		errors.email = (submit || email.value) && invalid ? 'Not a valid email' : '';
	};

	const validateNameNotEmpty = () => {
		errors.name = name.value.replace(/\s/g, '') === '' ? 'Name cannot be empty' : '';
	};

	const validateTermsAndConditions = submit => {
		if (submit || errors.termsAndConditions) {
			errors.termsAndConditions = !termsAndConditions.value;
		}
	};
	const validatePasswordsMatch = submit => {
		errors.confirmPassword =
			((password.value && confirmPassword.value) || submit) && password.value !== confirmPassword.value
				? 'Does not match with given password!'
				: '';
	};

	const validateAll = () => {
		validateEmailAddress(true);
		validateNameNotEmpty();
		validatePassword(true);
		validatePasswordsMatch(true);
		validateTermsAndConditions(true);
		return !hasValidationErrors();
	};

	return {
		errors,
		validateEmailAddress,
		validateNameNotEmpty,
		validateTermsAndConditions,
		validatePasswordsMatch,
		passwordHint,
		validatePassword,
		validateAll
	};
};
</script>
<style lang="scss" scoped>
.padding {
	padding: 16px;
}

.child:nth-child(even) {
	background-color: #9fffd2;
}

.grid:nth-child(odd) {
	background: #a0b7f8;
}

.login-container {
	min-height: 100%;
	display: flex;
	align-items: center;
}

.box {
	width: 700px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 16px 120px;
	@media (max-width: 480px) {
		padding: 16px 8px;
	}
	box-sizing: border-box;
	background: #fefefe;
	border-radius: 4px;
	margin: 0 auto;
}
</style>
