<template>
	<div class="all-set trial-box" :class="{'all-set--selected': environmentSelected }">
		<h3 v-if="environmentSelected" class="all-set__heading font-h2">
			<svg-icon icon="check" class="all-set__checkmark" />
			<span>All set!</span>
		</h3>
		<h3 v-else class="all-set__heading font-h2">Getting started</h3>

		<p v-if="environmentSelected">Now you can access your environment through the Control Tower.</p>
		<p v-else>Access your environment(s) through the Control Tower.</p>
		<p>You will automatically be logged in with the credentials you created when registering at cloud.avassa.io.</p>
		<p>When logged in, make sure you have a look at our tutorials:</p>

		<div class="all-set__tutorials">
			<div class="all-set__tutorial all-set__tutorial--first">
				<h4 class="font-h4">Deploy your first application:</h4>
				<external-link to="https://docs.avassa.io/tutorials/first-application" class="all-set__tutorial__screenshot" />
				<p class="font-secondary">This tutorial guides you through the steps to deploy your first application on the Avassa Platform.</p>
				<div class="all-set__tutorial__link">
					<a href="https://docs.avassa.io/tutorials/first-application" class="text-btn" target="_blank">
						Basic tutorial
						<svg-icon icon="open-in-new" />
					</a>
				</div>
			</div>
			<div class="all-set__tutorial all-set__tutorial--advanced">
				<h4 class="font-h4">Deploy more advanced applications:</h4>
				<external-link to="https://docs.avassa.io/tutorials/more-advanced-deploy" class="all-set__tutorial__screenshot" />
				<p class="font-secondary">This tutorial guides you through the steps to deploy more complex applications on the Avassa Platform. It adds on to the basic single-container, multi-site deployment covered in the first application tutorial.</p>
				<div class="all-set__tutorial__link">
					<a href="https://docs.avassa.io/tutorials/more-advanced-deploy" class="all-set__tutorial__link text-btn" target="_blank">
						Advanced tutorial
						<svg-icon icon="open-in-new" />
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ExternalLink from '@/components/ExternalLink';
import SvgIcon from '@/components/SvgIcon';
export default {
	components: {
		ExternalLink,
		SvgIcon
	},
	props: {
		environmentSelected: Boolean
	}
};
</script>

<style lang="scss" scoped>
.all-set {
	padding: $spacing;

	&.trial-box {
		border-color: $color-border;
	}

	&--selected.trial-box {
		border-color: $color-success;
	}

	&__heading {
		margin-bottom: $spacing;

		.all-set--selected & {
			display: flex;
			align-items: center;
			color: $color-text-success;
		}
	}

	&__checkmark {
		margin-right: $spacing-minus;
		padding: $spacing-xsmall;
		background-color: $color-background-success;
		fill: $color-success;
		border-radius: 50%;
		font-size: $icon-size-large
	}

	& > p {
		margin-bottom: $spacing;
	}

	&__tutorials {
		display: flex;
		padding-top: $spacing-small;

		@media all and (min-width: 70em) {
			display: block;
		}
	}

	&__tutorial {
		min-width: 40%;

		&--first {
			margin-right: $spacing;
			margin-bottom: $spacing-large;

			@media all and (min-width: 70em) {
				margin-right: 0;
			}
		}

		&__screenshot {
			display: flex;
			height: 10vw;
			background-color: $color-background-light;
			transition: all 300ms;
			padding: $spacing-xsmall;
			margin-bottom: $spacing-xsmall;

			&:hover {
				box-shadow: 0 3px 7px 1px $color-border;
			}

			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			flex-direction: column-reverse;
			border-radius: $border-radius;
			border: 1px solid $color-border;

			.all-set__tutorial--first & {
				background-image: url('~@/assets/tutorial-1.png');
			}
			.all-set__tutorial--advanced & {
				background-image: url('~@/assets/tutorial-2.png');
			}
		}

		&__link {
			margin-top: $spacing-small;
			text-align: right;
		}
	}
}
</style>
