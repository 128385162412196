<template>
	<div class="main korvilutt-background">
		<div class="logo-container">
			<img src="@/assets/avassa-logo-white.svg" width="200" height="34" alt="Avassa" />
		</div>
		<div class="flex" style="justify-content: center">
			<div class="card-1 bg-white align-center">
				<h1 class="align-center pt-64" style="font-size: 72px">404</h1>
				<h3 class="font-emphasize font-large pb-24">Page not found</h3>
				<p class="pb-24">Back to your <a href="/environments">environments</a>.</p>
				<p class="pb-80">
					Contact us at
					<a href="mailto:support@avassa.io">support@avassa.io</a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card-1 {
	width: 50vw;
	min-width: 500px;
	max-width: 850px;
	max-height: 650px;
	justify-self: center
}
</style>
