<template>
	<label class="checkbox" :class="{ 'checkbox--error': error, [`checkbox--${modifier}`]: modifier }">
		<span class="small-text">
			<slot></slot>
		</span>
		<input
			type="checkbox"
			:checked="modelValue"
			class="checkbox__input"
			@change="$emit('update:modelValue', $event.target.checked)"
		/>
		<span class="checkbox__checkmark"></span>
	</label>
</template>
<script>
export default {
	props: {
		modelValue: Boolean,
		error: Boolean,
		modifier: String
	}
};
</script>
<style lang="scss" scoped>
.checkbox {
	display: flex;
	align-items: center;
	position: relative;
	padding-left: 28px;
	margin-bottom: 12px;
	cursor: pointer;
	user-select: none;

	&--error {
		border-radius: 4px;
		border: 2px solid $color-border-error;
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&__checkmark {
		position: absolute;
		top: 2px;
		left: 2px;
		height: 14px;
		width: 14px;
		background-color: #fff;
		border: 1px solid #bec0c5;
		border-radius: 4px;

		&::after {
			content: '';
			position: absolute;
			display: none;
			top: 2px;
			left: 5px;
			width: 3px;
			height: 6px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}

		.checkbox:hover & {
			border-color: #838791;
			background-color: #f6f7f7;
		}

		.checkbox--error & {
			top: 10px;
		}
	}

	&__input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:focus ~ .checkbox__checkmark {
			height: 12px;
			width: 12px;
			border: 2px solid #79d2f3;
		}

		&:checked ~ .checkbox__checkmark {
			background: radial-gradient(248.95% 290.02% at -7.04% -9.29%, #ee4c72 0%, #f06255 100%);

			&::after {
				display: block;
			}
		}

		&:focus:checked ~ .checkbox__checkmark {
			&::after {
				top: 0;
				left: 4px;
			}
		}
	}

	&--medium {
		padding-left: 32px;

		.checkbox__checkmark {
			top: 0;
			left: 0;
			height: 18px;
			width: 18px;

			&::after {
				top: 2px;
				left: 6px;
				width: 4px;
				height: 9px;
			}
		}

		.checkbox__input {
			&:focus ~ .checkbox__checkmark {
				height: 16px;
				width: 16px;
			}
			&:focus:checked ~ .checkbox__checkmark {
				&::after {
					top: 1px;
					left: 5px;
				}
			}
		}
	}

	&--large {
		padding-left: 36px;

		.checkbox__checkmark {
			top: -2px;
			left: -2px;
			height: 22px;
			width: 22px;

			&::after {
				top: 3px;
				left: 8px;
				width: 5px;
				height: 11px;
			}
		}

		.checkbox__input {
			&:focus ~ .checkbox__checkmark {
				height: 20px;
				width: 20px;
			}
			&:focus:checked ~ .checkbox__checkmark {
				&::after {
					top: 2px;
					left: 7px;
				}
			}
		}
	}
}
</style>
