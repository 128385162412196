<template>
	<button type="button" :disabled="disabled" v-wave :class="['btn', 'font-semi-bold', { 'btn--large': large }]">
		<slot></slot>
		<span v-show="loading" class="lds-ring">
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</span>
	</button>
</template>
<script>
export default {
	props: {
		loading: Boolean,
		disabled: Boolean,
		large: Boolean
	}
};
</script>
<style lang="scss" scoped>
.lds-ring {
	display: inline-block;
	position: absolute;
	width: 28px;
	height: 28px;
	margin-left: 5px;
	right: 7px;

	span {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 19px;
		height: 19px;
		margin: 5px;
		border: 2px solid transparent;
		border-top-color: #fff;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;

		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
