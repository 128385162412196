<template>
	<div class="help-menu" v-click-outside="onClickOutside">
		<svg-icon
			element="button"
			type="button"
			icon="help"
			class="help-menu__trigger"
			:class="{ 'help-menu__trigger--open': showHelpMenu }"
			@click.stop="showHelpMenu = !showHelpMenu"
		/>
		<transition name="popup-menu">
			<div v-if="showHelpMenu" class="help-menu__popup">
				<div class="help-menu__area help-menu__area--header">
					<h3 class="font-h4">Do you need any help?</h3>
					<p class="font-secondary">
						Below you can find contact information and our documentation.
					</p>
				</div>
				<div class="help-menu__area">
					<a href="https://docs.avassa.io/" target="_blank" class="help-menu__link">
						<svg-icon icon="book" class="help-menu__link__icon" />
						Documentation
					</a>
				</div>
				<div class="help-menu__area">
					<a href="https://avassa.io/contact" target="_blank" class="help-menu__link">
						<svg-icon icon="email" class="help-menu__link__icon" />
						Contact us
					</a>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import SvgIcon from '@/components/SvgIcon.vue';

export default defineComponent({
	components: {
		SvgIcon
	},
	setup: () => {
		const showHelpMenu = ref(false);

		const onClickOutside = () => {
			showHelpMenu.value = false;
		}

		return {
			showHelpMenu,
			onClickOutside
		};
	}
});
</script>

<style lang="scss" scoped>
.help-menu {
	&__trigger {
		display: block;
		font-size: $icon-size-large;

		&--open {
			fill: $color-icon-selected;
		}
	}

	&__popup {
		transform-origin: 200px top;
		position: absolute;
		top: $top-menu-height;
		right: 0;
		z-index: $zindex-active-elements;
		min-width: 200px;
		max-width: 300px;
		background-color: $color-white;
		box-shadow: $box-shadow-top-menu-popup;
		pointer-events: auto;
		border-left: 1px solid $color-border;
		border-bottom: 1px solid $color-border;
		overflow: hidden;
	}

	&__area {
		&:not(:last-child) {
			border-bottom: 1px solid $color-border;
		}

		&--header {
			background-color: $color-background-light;
			text-align: center;
			padding: $spacing-large $spacing;
		}
	}

	h4 {
		font-size: $font-size-standard;
		color: $color-text-secondary;
		font-weight: $font-weight-bold;
		margin: 0;
	}

	&__link {
		display: block;
		width: 100%;
		padding: $spacing;
		transition: background duration(standard) ease;
		text-decoration: none;
		color: $color-text;

		&--arrow-right {
			background: $color-white url('~@/assets/icons/chevron-right.svg') no-repeat right $spacing top 50% /
				($icon-size-standard $icon-size-standard);
		}
		&--arrow-up {
			background: $color-white url('~@/assets/icons/chevron-up.svg') no-repeat right $spacing top 50% /
				($icon-size-standard $icon-size-standard);
		}

		&:hover {
			background-color: $color-background-hover;
		}

		&__icon {
			margin-right: $spacing-xsmall;
			vertical-align: middle;
			font-size: $icon-size-standard;
		}
	}
	&__about {
		padding: $spacing-xsmall $spacing $spacing $spacing;
	}
}
</style>