import { reactive } from 'vue';
import auth0 from 'auth0-js';
import { cookies, parseJwt } from '@/utils';
import { computed, toRef, watchEffect } from 'vue';
import useEnvironments from '@/modules/useEnvironments';
import useOrganization from '@/modules/useOrganization';
import { get, post } from '@/utils';

const databaseConnection = 'Username-Password-Authentication';
let webAuth;

const userState = reactive({
	userLoaded: false,
	isAuthenticated: false,
	hasSignedUp: false,
	error: null,
	user: {},
	hasOrganization: false,
	isLoading: false,
	isRefreshing: undefined,
	sessionTimeout: false,
	pollingInProgress: undefined,
	profile: undefined
});

const { environments: environmentsState } = useEnvironments();
const { organization: organizationState } = useOrganization();

const hasPendingActions = environments =>
	environments.some(
		({ status }) => status === 'CREATING' || status === 'CREATED' || status === 'DELETING'
	);

export default function useUser() {
	const waitForUser = () => {
		if (userState.isLoading) {
			//We're already fetching the user, just sit tight
			return new Promise(resolve => {
				watchEffect(() => {
					if (!userState.isLoading && userState.userLoaded) {
						resolve('done');
					}
				});
			});
		}
	};

	const getUser = async () => {
		userState.isLoading = true;
		const response = await get('/cloud-platform-api/users/me');
		if (response.status === 200) {
			const user = await response.json();
			const { organization, ...rest } = user;
			const { environments, ...restOrg } = organization;
			userState.user = rest;
			organizationState.value = restOrg;
			environmentsState.value = environments;
			userState.hasSignedUp = true;
		} else {
			userState.hasSignedUp = false;
		}
		userState.isLoading = false;
		userState.userLoaded = true;
		if (hasPendingActions(environmentsState.value)) {
			if (!userState.pollingInProgress) {
				userState.pollingInProgress = setInterval(getUser, 1000);
			}
		} else {
			clearInterval(userState.pollingInProgress);
			userState.pollingInProgress = undefined;
		}
	};

	const startSessionWatchdog = () => {
		setInterval(() => {
			if (!cookies()['acp-authn']) {
				userState.sessionTimeout = true;
			}
		}, 10000);
	};

	const init = () => {
		webAuth = new auth0.WebAuth({
			domain: process.env.VUE_APP_AUTH0_DOMAIN,
			clientID: process.env.VUE_APP_AUTH0_CLIENT_ID,
			redirectUri: window.location.host
		});

		const userInfo = cookies()['acp-authn'];
		if (userInfo) {
			userState.isAuthenticated = true;
			userState.profile = parseJwt(userInfo);
			startSessionWatchdog();
			getUser();
		}
	};

	const signup = (username, password, given_name, family_name, country, newsletterConsent, cb) => {
		webAuth.signup(
			{
				email: username,
				password,
				connection: databaseConnection,
				name: `${given_name} ${family_name}`,
				given_name,
				family_name,
				user_metadata: {
					newsLetter: newsletterConsent.toString(),
					country
				}
			},
			cb
		);
	};

	function toggleMfaEnabled(mfaEnabled) {
		post('/cloud-platform-api/users/command/set-mfa', {
			mfaEnabled
		});
	}
	return {
		getUser,
		waitForUser,
		isAuthenticated: computed(() => {
			return userState.isAuthenticated;
		}),
		userLoaded: computed(() => {
			return userState.userLoaded;
		}),
		hasSignedUp: computed(() => userState.hasSignedUp),
		hasEnvironments: computed(() => {
			return environmentsState.value?.length > 0;
		}),
		sessionTimeout: toRef(userState, 'sessionTimeout'),
		userState,
		init,
		signup,
		resetPassword: (email, cb = () => {}) => {
			webAuth.changePassword({ email, connection: databaseConnection }, cb);
		},
		toggleMfaEnabled
	};
}
