<template>
	<div class="email-sent card-1" data-cy="email-sent">
		<h1 class="mb-16">Thank you for signing up</h1>
		<p>
			We sent you an email - please, find it in your mailbox and click the link
			to verify it and continue the registration process.
		</p>

		<h2 class="font-h4">Did not receive an email?</h2>
		<p>Check your spam filter or wait a few minutes.</p>

		<h2 class="font-h4">Still no email?</h2>
		<p>
			Contact us at
			<a href="mailto:support@avassa.io" class="font-bold">support@avassa.io</a>
		</p>
	</div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.email-sent {
	max-width: 500px;
	margin: 0 auto;
	background-color: $color-white;
	padding: $spacing-xlarge;

	p {
		margin-bottom: $spacing;
	}
}
</style>
