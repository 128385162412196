<template>
	<div class="main korvilutt-background">
		<div class="logo-container">
			<img src="@/assets/avassa-logo-white.svg" width="200" height="34" alt="Avassa" />
		</div>
		<div class="card-1 bg-white align-center">
			<h1 class="align-center pt-64 font-h1">Please validate your email</h1>
			<p class="align-center text-mute">You can do this by clicking the link in your welcome email.</p>
			<p class="font-emphasize pt-16">Did not receive an email?</p>
			<p>Check your spam filter or wait a few minutes.</p>
			<p class="font-emphasize pt-16">Still no email?</p>
			<p class="pb-64">
				Contact us at
				<a href="mailto:support@avassa.io" class="font-bold">support@avassa.io</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card-1 {
	width: 50vw;
	min-width: 500px;
	max-width: 850px;
	max-height: 650px;
	justify-self: center
}
</style>
