<template>
	<div class="main korvilutt-background">
		<div class="logo-container">
			<img src="@/assets/avassa-logo-white.svg" width="200" height="34" alt="Avassa" />
		</div>
		<div class="content">
			<div class="card-1 bg-white flex" style="flex-direction: column; justify-content: center; padding: 36px">
				<div class="flex text-black" style="flex-direction: column">
					<div style="max-width: 450px">
						<div class="font-h1 pb-4 align-center">Set up your account</div>
						<div class="font-emphasize text-mute align-center">
							Please provide the following information.
						</div>
						<InputField
							name="Name of your company"
							v-model="organizationName"
							required
							:error="orgNameValidationError"
							@update:modelValue="setEscapedCM"
							class="pt-32 pb-16"
						/>
						<InputField
							name="Your Avassa subdomain name"
							v-model="escapedCM"
							required
							:error="escapedCMError"
						/>
						<p class="font-secondary pt-4">
							The URL to your environments will be
							<span v-if="!escapedCM">of the form </span>
							<strong class="url-example">
								[environment name].{{escapedCM || '[subdomain]'}}.avassa.net
							</strong>
						</p>
					</div>

					<LoadingButton @click="submitOrg(false)" class="mt-32" style="align-self: flex-end" :loading="loading" w-vave>Finish</LoadingButton>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { hri } from 'human-readable-ids';
import { useRouter } from 'vue-router';
import InputField from '@/components/InputField';
import LoadingButton from '@/components/LoadingButton';
import { ref, watch } from 'vue';
import useOrganization from '@/modules/useOrganization';
import useUser from '@/modules/useUser';

export default {
	components: { LoadingButton, InputField },
	setup() {
		const { createOrganization, validateCloudManagerName } = useOrganization();
		const { getUser } = useUser();
		const router = useRouter();
		const organizationName = ref('');
		const orgNameValidationError = ref('');
		const escapedCMError = ref('');
		const loading = ref(false);
		const escapedCM = ref('');
		const setEscapedCM = val => {
			orgNameValidationError.value = '';
			escapedCM.value = val
				.toLocaleLowerCase()
				.replaceAll(' ', '-')
				.replace(/[^a-öA-Ö0-9\\-]/g, '')
				.replace(/[åä]/g, 'a')
				.replace(/ö/g, 'o');
		};
		watch(
			() => escapedCM.value,
			async () => {
				escapedCMError.value = /[^a-z0-9-].*/.test(escapedCM.value)
					? 'Please only use a-z, 0-9 and -'
					: escapedCM.value?.length > 32
					? 'Max subdomain length is 32 chars'
					: escapedCM.value?.endsWith('-')
					? 'Subdomain must end with a-z, 0-9'
					: escapedCM.value && !await validateCloudManagerName(escapedCM.value)
					? 'Subdomain name is already in use'
					: '';
			}
		);
		const submitOrg = async () => {
			if (organizationName.value.trim() === '') {
				orgNameValidationError.value = 'Enter at least one character!';
			}
			if (escapedCM.value.trim() === '') {
				escapedCMError.value = 'Enter at least one character!';
			}
			if (!escapedCMError.value && !orgNameValidationError.value) {
				loading.value = true;
				orgNameValidationError.value = '';
				escapedCMError.value = '';
				const success = await createOrganization({
					organizationName: organizationName.value,
					edgeCloudManager: escapedCM.value,
					organizationId: hri.random(),
					purpose: ''
				});
				loading.value = false;
				if (success) {
					await getUser();
					await router.push('/create-environment');
				}
			}
		};
		return {
			submitOrg,
			organizationName,
			loading,
			orgNameValidationError,
			escapedCM,
			escapedCMError,
			setEscapedCM
		};
	}
};
</script>
<style lang="scss" scoped>
.input-label {
	text-align: left;
}
.url-example {
	white-space: nowrap
}
</style>
