<template>
	<div class="trial-box">
		<h3 class="font-h4">
			Your environment is being set up in the background.
		</h3>
		<p>
			This is usually done in less than 5 minutes, but may take up to 10 to 15 minutes to complete.
		</p>
		<p>
			When setup is finished you will be able to manage your environment through the Control Tower.
		</p>
	</div>
</template>

<script>
</script>

<style lang="scss" scoped>
.trial-box {
	padding: $spacing-xlarge;

	h3,
	p {
		margin-bottom: $spacing-minus;
	}
}
</style>
