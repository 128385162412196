import { createRouter, createWebHistory } from 'vue-router';
import useUser from '@/modules/useUser';
import CreateAccount from '@/views/CreateAccount/CreateAccount';
import CreateEnvironment from '@/views/CreateEnvironment/CreateEnvironment';
import ResetPassword from '@/views/ResetPassword';
import CreateOrg from '@/views/CreateOrganization';
import { apiUrl, host } from '@/utils';
import EnvironmentsList from '@/views/EnvironmentsList';
import MainView from '@/views/MainView';
import PageNotFound from '@/views/PageNotFound';
import ValidateEmail from '@/views/ValidateEmail';
import UnauthorizedPage from '@/views/UnauthorizedPage';
import CreateAccountHS from '@/views/CreateAccount/CreateAccountHS';

const { userState, hasSignedUp, hasEnvironments, waitForUser } = useUser();
const onAuthRequired = async (to, from, next) => {
	if (userState.isAuthenticated) {
		await waitForUser();
		if (!hasSignedUp.value && to.fullPath !== '/create-organization') {
			next('/create-organization');
		} else if (!hasEnvironments.value && hasSignedUp.value && to.fullPath !== '/create-environment') {
			next('create-environment');
		} else {
			next(); // user shall pass
		}
		return;
	}
	next(false);
	window.location = `${apiUrl()}/login?redirectUrl=${host}${encodeURIComponent(to.fullPath)}`;
};

const routes = [
	{ path: '/:pathMatch(.*)*', component: PageNotFound },
	{
		path: '/',
		redirect: '/environments'
	},
	{
		path: '/create-account',
		name: 'Create account',
		component: CreateAccount
	},
	{
		path: '/create-hs-account',
		name: 'Create HS account',
		component: CreateAccountHS
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: ResetPassword
	},
	{
		path: '/validate-email',
		name: 'ValidateEmail',
		component: ValidateEmail
	},
	{
		path: '/401',
		name: 'UnauthorizedPage',
		component: UnauthorizedPage
	},
	{
		path: '/create-organization',
		name: 'CreateOrganization',
		component: CreateOrg,
		beforeEnter: onAuthRequired
	},
	{
		path: '/',
		name: 'MainView',
		component: MainView,
		props: true,
		beforeEnter: onAuthRequired,
		children: [
			{
				path: 'create-environment',
				name: 'CreateEnvironment',
				component: CreateEnvironment
			},
			{
				path: 'environments',
				name: 'Environments',
				component: EnvironmentsList
			},
			{
				path: 'environments?:name',
				name: 'EnvironmentDetails',
				component: EnvironmentsList,
				props: true
			}
		]
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router;
