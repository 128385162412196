<template>
	<router-view v-slot="slotProps">
		<transition name="fade" mode="out-in">
			<component :is="slotProps.Component"></component>
		</transition>
	</router-view>
</template>

<script>
export default {
	setup() {
		return {};
	}
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}
.fade-enter-from,
.fade-leave-active {
	opacity: 0;
}

html,
body,
#app {
	height: 100%;
}
</style>
