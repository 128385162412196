<template>
	<div class="sidebar">
		<h1 class="sidebar__header">
			<img src="@/assets/avassa-logo-white.svg" class="sidebar__header__logo" alt="Avassa" />
			<span class="sidebar__header__sub-header">CLOUD PLATFORM</span>
		</h1>
		<div v-if="env" class="env-banner">{{ env }}</div>
		<div class="sidebar__menu-items">
			<router-link to="/environments" class="sidebar__menu-item" active-class="sidebar__menu-item--selected">
				<svg-icon icon="environments" class="sidebar__menu-item__icon" />
				<span class="sidebar__menu-item__text">Environments</span>
			</router-link>
			<router-link to="/create-environment" class="sidebar__menu-item" active-class="sidebar__menu-item--selected" data-cy="sidebar__create-new">
				<svg-icon icon="plus" class="sidebar__menu-item__icon" />
				<span class="sidebar__menu-item__text">Create new</span>
			</router-link>
		</div>
	</div>
</template>
<script>
import { onBeforeUnmount, ref } from 'vue';
import useEnvironments from '@/modules/useEnvironments';
import { calcProgress, env } from '@/utils';
import SvgIcon from "@/components/SvgIcon";

export default {
	components: {
		SvgIcon
	},
	setup() {
		const { environments } = useEnvironments();
		const timestamp = ref(new Date());
		const timerId = setInterval(() => {
			timestamp.value = new Date();
		}, 1000);

		onBeforeUnmount(() => {
			clearInterval(timerId);
		});
		return {
			env,
			environments,
			calcProgress,
			timestamp
		};
	}
};
</script>
<style lang="scss" scoped>
.sidebar {
	color: $color-white;
	background: $color-background-dark url(~@/assets/korvilutt2.svg) bottom -40px left -15px / 250px 250px no-repeat;

	&__header {
		display: flex;
		padding: $spacing;
		background-color: $color-avassa-black;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;

		&__logo {
			width: 100%;
		}

		&__sub-header {
			width: 100%;
			margin-top: $spacing-xsmall;
			font-size: 12px;
			letter-spacing: .3px;
			text-align: center;
		}
	}

	&__menu-items {
		@extend %flex-child;
		@extend %flex-parent-col;
		flex-shrink: 0;
		align-items: stretch;
		margin-top: $spacing-large;

		&__title {
			margin-left: $spacing;
			font-size: $icon-caption-size;
			text-transform: uppercase;
			font-weight: $font-weight-light;
			line-height: 18px;
		}
	}

	&__menu-item {
		@extend %flex-child-grow;
		@extend %flex-parent-row;
		align-items: center;
		align-content: space-between;
		cursor: pointer;
		color: $color-main-menu-foreground;
		transition: background-color duration(standard) ease;
		line-height: 38px;
		height: 38px;
		text-decoration: none;

		&::before {
			content: '';
			width: 200px;
			height: 100%;
			background-color: $color-accent-1;
			opacity: 0;
			border-radius: 50%;
			margin-left: -196px;
			flex-shrink: 0;
			transition: all duration(standard) ease;
		}

		&__icon {
			pointer-events: none;
			margin-bottom: $spacing-xxsmall;
			margin-left: $spacing-small;
			padding: $spacing-xsmall;
			font-size: $icon-size-large;

			&.svg-icon--filled {
				fill: $color-main-menu-foreground;
			}
			&.svg-icon--stroked {
				stroke: $color-main-menu-foreground;
			}
		}

		&__text {
			display: block;
			margin-left: $spacing-xsmall;
			font-size: $icon-caption-size;
			font-weight: $font-weight-semi-bold;
			line-height: 1.4;
			transition: opacity duration(quick) ease, padding duration(standard) ease;
			opacity: 1;

			&--hidden {
				opacity: 0;
				pointer-events: none;
			}
		}

		&:hover {
			background-color: $color-background-dark-hover;

			.sidebar__menu-item__text--hidden {
				pointer-events: auto;
				opacity: 1;
				z-index: $zindex-active-elements;
				background-color: $color-background-dark-hover;
				line-height: 38px;
				padding: 0 $spacing-plus 0 $spacing-small;
				border-radius: 0 50px 50px 0;
			}
		}

		&--selected,
		&--selected:hover {
			color: $color-main-menu-foreground-selected;
			background-color: $color-black;

			&::before {
				border-radius: $border-radius;
				opacity: 1;
			}

			.sidebar__menu-item__icon {
				&.svg-icon--filled {
					fill: $color-main-menu-foreground-selected;
				}
				&.svg-icon--stroked {
					stroke: $color-main-menu-foreground-selected;
				}
			}
		}

		&--selected:hover {
			.sidebar__menu-item__text--hidden {
				background-color: $color-black;
			}
		}
	}
}
.env-banner {
	display: flex;
	justify-content: center;
	align-items: center;
	background: $color-accent-1;
	color: $color-white;
	font-size: $font-size-emphasize;
	height: 40px;
}
</style>
