<template>
	<div class="dropdown full-width mb-16" :class="{ 'validation-error': !!error }">
		<label class="pb-4" :for="label">
			{{ label }}
			<span v-if="optional" class="font-body">(optional)</span>
			<span v-if="required" class="font-semi-bold text-accent">*</span>
		</label>
		<select
			:id="label"
			:name="label"
			class="dropdown__select"
			:class="{ 'dropdown__select--unselected': !modelValue }"
			@input="$emit('update:modelValue', $event.target.value)"
		>
			<option
				v-for="item in options"
				:key="item"
				:value="item === placeholderItem ? '' : item"
				:disabled="item === placeholderItem"
				:selected="item === placeholderItem"
			>
				{{ item }}
			</option>
		</select>
		<div v-if="!!error" class="small-text align-left font-error pl-16">{{ error }}</div>
		<div v-else-if="!!hint" class="small-text align-left pl-16">{{ hint }}</div>
	</div>
</template>
<script>
export default {
	props: {
		name: String,
		modelValue: [Number, String],
		optional: Boolean,
		required: Boolean,
		options: Array,
		placeholderItem: String,
		error: String,
		hint: String,
		label: String
	},
	setup() {
		return {};
	}
};
</script>
<style lang="scss" scoped>
.dropdown {
	&__select {
		display: block;
		box-sizing: border-box;
		width: 100%;
		max-width: 100%;
		margin: 1px;
		outline: none;
		border: 1px solid #bec0c5;
		border-radius: 4px;
		padding: 10px 16px;
		background: #fff url('~@/assets/icons/chevron-down.svg') no-repeat right 1em top 50%;
		font-family: Gotham, sans-serif;
		font-size: 16px;
		font-weight: normal;
		line-height: 22px;
		color: #000;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;

		/* Hide arrow icon in IE browsers */
		&::-ms-expand {
			display: none;
		}

		&:hover {
			border-color: #888;
		}

		&:focus {
			border: 2px solid #79d2f3;
			margin: 0;
			color: #222;
			outline: none;
		}

		&:disabled,
		&:disabled:hover {
			background-color: #f7f7f7;
			color: #777;
		}

		&--unselected {
			color: #777;
		}

		.validation-error & {
			border: 2px solid #b00020;
			margin: 0;
		}

		option {
			font-weight: normal;
		}
	}
}
</style>
