<template>
	<div class="environment-rename">
		<div class="environment-rename__form-elements">
			<input
				ref="inputElementRef"
				id="environmentInput"
				class="input font-h2"
				type="text"
				v-model="nameRef"
				maxlength="20"
				@keyup="onKeyup"
				autocomplete="off"
			/>
			<button type="button" :disabled="invalidName || !isChanged" class="btn" @click="rename">Rename</button>
			<button type="button" class="btn btn--secondary" @click="cancel">Cancel</button>
		</div>
		<div v-if="invalidName" class="font-error">Allowed characters in name are <strong>A-Z</strong>, <strong>a-z</strong>, <strong>0-9</strong> and <strong>-</strong></div>
	</div>
</template>
<script>
import { ref, unref, onMounted, watch, computed } from 'vue';

export default {
	props: {
		modelValue: String
	},
	emits: [
		'save',
		'cancel'
	],
	setup(props, { emit }) {
		let name = unref(props.modelValue);
		const nameRef = ref(name);
		const inputElementRef = ref(null);

		const isChanged = computed(() => nameRef.value !== name);
		const invalidName = computed(() => nameRef.value.trim() === '' || /[^A-Za-z0-9-]/g.test(nameRef.value));

		const onKeyup = e => {
			if (e.keyCode === 27) {
				cancel();
			} else if (e.keyCode === 13) {
				rename();
			}
		};

		const rename = () => {
			if (isChanged.value && !invalidName.value) {
				emit('save', nameRef.value);
			}
		};

		const cancel = () => {
			nameRef.value = name;
			emit('cancel');
		};

		watch(
			() => props.modelValue,
			() => {
				name = unref(props.modelValue);
				nameRef.value = name;
			}
		);

		onMounted(
			()  => {
				inputElementRef.value.focus();
			}
		);

		return {
			nameRef,
			inputElementRef,
			onKeyup,
			cancel,
			rename,
			invalidName,
			isChanged
		};
	}
};
</script>
<style lang="scss" scoped>
.environment-rename {
	margin-bottom: $spacing;

	&__form-elements {
		display: flex;

		input {
			flex-grow: 1;
			border: 1px solid $color-border;
			border-radius: $border-radius;
			padding-left: $spacing-small;
			padding-right: $spacing-small;

			&:focus {
				border: 1px solid $color-border-focus;
			}
		}
		button {
			margin-left: $spacing-small;
			flex-grow: 0;
		}
	}
}
</style>
