import { ref } from 'vue';
import { hri } from 'human-readable-ids';
import useUser from '@/modules/useUser';
import { post } from '@/utils';

const environmentsState = ref([]);
const organizationId = ref('');
export default function useEnvironments() {
	const { getUser } = useUser();

	const setOrganizationId = orgId => (organizationId.value = orgId);

	const addEnvironment = async (environmentName, category) => {
		const res = await post('/cloud-platform-api/organizations/command/add-environment', {
			environmentName,
			environmentId: hri.random(),
			category
		});

		if (res.status === 204) {
			environmentsState.value.push({
				name: environmentName,
				createdAt: new Date(),
				status: 'CREATING'
			});
			getUser();
		}
		return res;
	};

	const renameEnvironment = async ({ environmentId, environmentName }) => {
		const res = await post('/cloud-platform-api/organizations/command/rename-environment', {
			environmentName,
			environmentId
		});
		const success = res.status === 204;
		if (success) {
			environmentsState.value.find(({ environmentId: pid }) => pid === environmentId).name = environmentName;
			return success;
		}
	};

	const deleteEnvironment = async environmentId => {
		const res = await post('/cloud-platform-api/organizations/command/delete-environment', {
			environmentId
		});
		if (res.status === 204) {
			const environment = environmentsState.value.find(({ environmentId: pid }) => pid === environmentId);
			environment.status = 'deleting';
			getUser();
			return true;
		}
		return false;
	};
	return {
		environments: environmentsState,
		addEnvironment,
		renameEnvironment,
		deleteEnvironment,
		setOrganizationId
	};
}
