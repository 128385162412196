import { reactive } from 'vue';
import { post, get } from '@/utils';

const state = reactive({ organization: {} });

export default function useOrganization() {
	return {
		organization: state.organization,
		createOrganization: async ({ organizationName, organizationId, edgeCloudManager, purpose }) => {
			const res = await post('/cloud-platform-api/organizations/command/sign-up', {
				organizationId,
				organizationName,
				edgeCloudManager,
				purpose
			});
			if (res.status === 204) {
				state.organization.name = organizationName;
				state.organization.id = organizationId;
				return true;
			}
			return false;
		},
		validateCloudManagerName: async (edgeCloudManager) => {
			const res = await get('/cloud-platform-api/organizations/edge-cloud-manager-name-is-valid/' + edgeCloudManager);
			return res?.status === 204 && res?.ok;
		}
	};
}