<template>
	<div class="main-view">
		<SidebarMenu class="main-view__sidebar" />
		<session-info />
		<div class="main-view__page">
			<app-bar class="main-view__appbar" />
			<div class="main-view__content">
				<router-view />
			</div>
		</div>
	</div>
</template>
<script>
import SidebarMenu from '@/components/SidebarMenu';
import AppBar from '@/components/AppBar';
import SessionInfo from '@/components/SessionInfo';
export default {
	components: { SessionInfo, AppBar, SidebarMenu }
};
</script>
<style scoped lang="scss">
.main-view {
	position: fixed;
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	height: 100%;
	width: 100%;

	&__sidebar {
		display: flex;
		flex-direction: column;
		width: 160px;
		min-height: 100vh;
		height: 100%;
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: auto;
		min-width: 0;
	}

	&__page {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
		min-height: 0;
		min-width: 0;
	}

	&__content {
		box-sizing: border-box;
		padding: $spacing-xlarge;
		overflow: auto;

		& * {
			text-align: left;
		}
	}
}
</style>
