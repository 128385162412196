<template>
	<div class="choose-category">
			<div v-if="type === 'production'">
				<h3>Create a 14 day-trial environment instead</h3>
				<div class="flex">
					<p>
						If you want to test a bit more before you go live, you can start a free trial account instead.
						Our trial environment is prepared with 2 virtual edge sites to get started with, and they are the first component needed to go through our
						<a href="https://docs.avassa.io/tutorials/first-application" class="text-btn" target="_blank">
							basic
							<svg-icon icon="open-in-new" />
						</a>
						and
						<a href="https://docs.avassa.io/tutorials/more-advanced-deploy" class="all-set__tutorial__link text-btn" target="_blank">
							advanced
							<svg-icon icon="open-in-new" />
						</a>
						tutorials.
					</p>
					<p class="choose-category__btn-container">
						<button type="button" class="btn btn--secondary" @click="$emit('update:type', 'trial')" v-wave>Create trial environment</button>
					</p>
				</div>
			</div>
			<div v-else>
				<h3>Interested in our production environment?</h3>
				<div class="flex">
					<p>
						Our production environment is for commercial use and provides additional resources for scaling to very large numbers of edge sites.
						<span v-if="!isContractedCustomer">Please contact us if you are interested in getting access to a production-grade environment.</span>
					</p>
					<p class="choose-category__btn-container">
						<button
							v-if="isContractedCustomer"
							type="button"
							class="btn btn--secondary"
							@click="$emit('update:type', 'production')"
							v-wave
						>
							Create production environment
						</button>
						<a v-else :href="mailTo" class="mailto-btn btn btn--secondary" v-wave>Contact sales</a>
					</p>
				</div>
			</div>
	</div>
</template>

<script>
import useUser from '@/modules/useUser';
import SvgIcon from "@/components/SvgIcon";

export default {
	components: {
		SvgIcon
	},
	props: {
		type: String,
		isContractedCustomer: Boolean
	},
	setup() {
		const { userState } = useUser();

		const mailTo = `mailto:hello@avassa.com?subject=Requesting production access&body=Hello Avassa! %0D%0A %0D%0A I'd like to be able to create production type environments! %0D%0A %0D%0A Best Regards, %0D%0A ${userState.user.name} `;

		return {
			mailTo
		};
	}
};
</script>

<style lang="scss" scoped>
.choose-category {
	border: 1px transparent;
	padding: $spacing-xlarge;

	&__btn-container {
		flex-shrink: 0;
		margin-left: $spacing;
	}

	h3 {
		margin-bottom: $spacing-minus;
	}

	.text-btn {
		padding: 0;
		line-height: inherit;
		vertical-align: unset;
		white-space: nowrap;
	}

	.mailto-btn {
		display: block;
	}
}
</style>
