<template>
	<a :href="to" @click="gotoLink"><slot></slot></a>
</template>

<script>
export default {
	props: ['to'],

	setup(props) {
		function gotoLink(e) {
			e.preventDefault();
			window.open(props.to).focus();
		}
		return { gotoLink };
	}
};
</script>

<style lang="scss" scoped>
a {
	text-decoration: none;
	color: $color-text;
}
</style>
